import { produce } from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { EmployerContext } from '../../../context-providers/employer/employer-context';
import { IEmployer } from '../../../context-providers/employer/employer-reducer';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { IconArrowRight } from '../../icons';

export interface IEditEmployerAddress {
  loadEmployer?: () => void;
}

const EditEmployerAddress = ({ loadEmployer }: IEditEmployerAddress) => {
  const closeModal = useCloseModal();
  const { state } = useContext(EmployerContext);
  const [step, setStep] = useState(0);
  const [employer, setEmployer] = useState<IEmployer | null>(
    state.currentEmployer,
  );

  useEffect(() => setEmployer(state.currentEmployer), [state.currentEmployer]);
  if (employer === null) {
    return null;
  }

  const handleChange = (value: string, fieldName: string) => {
    setEmployer(
      produce(employer, (newEmployer) => {
        set(newEmployer, fieldName, value);
        return newEmployer;
      }),
    );
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    const res = await fetchAPI({
      method: 'PUT',
      body: employer,
      path: `EmployerRecords/${employer.id}`,
      responseType: 'text',
    });
    if (res.success) {
      loadEmployer && loadEmployer();
      closeModal();
    }
  };

  // field errors
  const { building, street, county, city, postcode, phone } =
    employer?.address || {};
  const buildingErr = validateField(building, { required: true });
  const streetErr = validateField(street, { required: true });
  const countyErr = validateField(county, { required: true });
  const cityErr = validateField(city, { required: true });
  const postcodeErr = validateField(postcode, { required: true });
  const step1valid =
    !buildingErr && !streetErr && !countyErr && !cityErr && !postcodeErr;

  const phoneErr = validateField(phone, { required: true });
  const step2valid = !phoneErr;

  return (
    <form onSubmit={handleSubmit}>
      {step === 0 ? (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <h2>Edit address and phone number</h2>
            <p>
              Please enter the address details and phone number for the employer
              record.
            </p>
          </Stack>
          <Stack stackMultiplier={0.5}>
            <h2>Address</h2>
            <TextInput
              label="Building and street"
              value={building || ''}
              id="building"
              error={buildingErr}
              dataQa="editAddress-input-building"
              onChangeText={(str) => handleChange(str, 'address.building')}
            />
            <TextInput
              value={street || ''}
              id="street"
              error={streetErr}
              dataQa="editAddress-input-street"
              onChangeText={(str) => handleChange(str, 'address.street')}
            />
          </Stack>
          <Stack stackMultiplier={1}>
            <TextInput
              label="Town or city"
              value={city || ''}
              id="city"
              error={cityErr}
              dataQa="editAddress-input-city"
              onChangeText={(str) => handleChange(str, 'address.city')}
            />
            <TextInput
              label="County"
              value={county || ''}
              id="county"
              error={countyErr}
              dataQa="editAddress-input-county"
              onChangeText={(str) => handleChange(str, 'address.county')}
            />
            <TextInput
              label="Postcode"
              value={postcode || ''}
              id="postcode"
              error={postcodeErr}
              dataQa="editAddress-input-postcode"
              onChangeText={(str) => handleChange(str, 'address.postcode')}
            />
          </Stack>
          <ButtonRow>
            <Button
              onClick={() => closeModal()}
              variant="subtle"
              dataQa="editAddress-btn-cancel"
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                setStep(1);
              }}
              disabled={!step1valid}
              iconRight={<IconArrowRight responsive />}
              dataQa="editAddress-btn-next"
            >
              Add phone number
            </Button>
          </ButtonRow>
        </Stack>
      ) : step === 1 ? (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <h2>Edit address and phone number</h2>
            <p>
              Please enter the address details and phone number for the employer
              record.
            </p>
          </Stack>
          <Stack stackMultiplier={0.5}>
            <TextInput
              label="Phone number"
              value={phone || ''}
              id="phone"
              error={phoneErr}
              dataQa="editAddress-input-phone"
              onChangeText={(str) => handleChange(str, 'address.phone')}
            />
          </Stack>
          <ButtonRow>
            <Button
              onClick={() => closeModal()}
              variant="subtle"
              dataQa="editAddress-btn-cancel"
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              dataQa="editAddress-btn-back"
              onClick={() => {
                setStep(0);
              }}
            >
              Back
            </Button>
            <Button
              type="submit"
              disabled={!step2valid}
              dataQa="editAddress-btn-save"
            >
              Save details
            </Button>
          </ButtonRow>
        </Stack>
      ) : null}
    </form>
  );
};

export default EditEmployerAddress;
