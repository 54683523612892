import styled from 'styled-components';
import { color } from '../../config';

interface IDataCardContainer {
  hasBackground: boolean;
  isEmpty: boolean;
}

export const DataCardContainer = styled.div<IDataCardContainer>`
  border-radius: 0.8rem;
  background-color: ${(props) =>
    props.isEmpty
      ? color.error.background
      : props.hasBackground
      ? color.grey.lightest
      : 'white'};
  border: 1px solid ${color.grey.lightest};
`;

export const DataCardTitle = styled.div`
  display: flex;
  align-items: flex-start;
  h3 {
    flex: 1;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 2rem;
  }
`;

export const DataCardIcon = styled.button`
  color: ${color.primary.base};
  padding: 1.6rem 2rem;
  background: none;
  border: none;
  cursor: pointer;
  svg {
    width: 1.5rem;
  }
`;

export const DataCardContent = styled.div`
  padding: 0 2rem 2rem;
`;

export const DataCardAddDetails = styled.div`
  padding: 2.4rem;
  padding-top: 0;
  button {
    border: 1px dashed ${color.primary.base};
    border-radius: 12px;
    width: 100%;
    padding: 1.6rem 2.4rem;

    div {
      width: 4rem;
      height: 4rem;
    }

    svg {
      width: 2rem;
    }

    &:focus {
      outline-offset: 3px;
    }
  }
`;

export const DataCardItemError = styled.div`
  color: ${color.error.foreground};
  svg {
    vertical-align: text-bottom;
    margin-right: 0.5rem;
  }
`;

export const DataCardItemContainer = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
`;
