import React, { useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import Button from '../../atoms/button/Button';
import { fetchAPI } from '../../../utils/fetch-api';
import { ButtonRow } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import { IFormInReview } from '../../../context-providers/forms/forms-reducer';
import PDFExport from '../../organisms/pdf-export/PDFExport';
import LoaderOverlay from '../../atoms/loader-overlay/LoaderOverlay';
import { PDFExportMessage } from '../../organisms/pdf-export/PDFExport.style';
import { color } from '../../config';

interface IDownloadForm {
  formId: string;
}

const DownloadForm = ({ formId }: IDownloadForm) => {
  const closeModal = useCloseModal();
  const [isLoading, setLoading] = useState(true);
  const [formInReview, setFormInReview] = useState<IFormInReview | null>(null);
  const load = useCallback(async () => {
    const res = await fetchAPI<IFormInReview>({
      path: `CompletedFormAssignments/completed/${formId}`,
    });
    if (res.success) {
      setFormInReview(res.body);
      setLoading(false);
    } else {
      setLoading(false);
      closeModal();
      navigate('/');
    }
  }, [formId, closeModal]);

  useEffect(() => {
    if (!formInReview) {
      load();
    }
  }, [load, formInReview]);

  const text = <p>Form loading...</p>;

  if (!formInReview) {
    return (
      <LoaderOverlay loading={isLoading} color={color.primary.base}>
        <PDFExportMessage>{text}</PDFExportMessage>
      </LoaderOverlay>
    );
  }

  return (
    <Stack stackMultiplier={2}>
      <h2>Your PDF is being processed</h2>
      <p>
        It'll take a few seconds to process. When it's complete, you'll be able
        to download the PDF.
      </p>
      <ButtonRow>
        <Button
          onClick={() => {
            closeModal();
          }}
          variant="secondary"
          dataQa="downloadForm-btn-cancel"
        >
          Cancel
        </Button>

        <PDFExport
          formTemplate={formInReview.template}
          learnerName={formInReview.learner.name}
        />
      </ButtonRow>
    </Stack>
  );
};

export default DownloadForm;
