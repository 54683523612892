import styled from 'styled-components';
import { Link } from 'gatsby';
import { color } from '../../config';

export const FullWidthCropContainer = styled.div`
  position: relative;
  background-color: ${color.light};
  padding: 0 3.2rem;
  box-shadow: 0px 4px 4px 0 rgba(44, 51, 63, 0.1);
`;

export const TabLinks = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  a {
    font-size: 1.6rem;
    margin-right: 4rem;
    display: inline-block;
    min-width: 3rem;
    text-align: center;
  }
`;

export const TabLink = styled(Link)`
  display: block;
  border-bottom: 4px solid transparent;
  padding: 1.2rem 0;
  text-decoration: none;
  color: ${color.grey.dark};
  font-weight: 400;

  &[aria-selected='true'] {
    border-color: ${color.primary.base};
    color: ${color.primary.base};
    font-weight: 600;
  }
`;

export const Actions = styled.div`
  margin-left: auto;
`;

export const TabSection = styled.div`
  margin: 3.8rem;
  border-radius: 0.8rem;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(44, 51, 63, 0.08);
`;
