import React from 'react';
import { BadgeContainer } from './StatusBadge.style';

export interface IStatusBadge {
  children?: React.ReactNode;
  color?: string;
  id?: string;
  border?: string;
  text?: string;
}

const StatusBadge = ({
  id = undefined,
  color,
  border,
  text,
  children,
}: IStatusBadge) => (
  <BadgeContainer color={color} border={border} text={text} data-id={id}>
    {children}
  </BadgeContainer>
);

export default StatusBadge;
