import React from 'react';
import { WordWrapBox } from '../../../layout/Layout.style';
import Label from '../label/Label';
import {
  InputRadio,
  InputRadioField,
  RadioContainer,
  InputRadioProps,
} from './Radio.style';
export interface IRadio extends InputRadioProps {
  checked?: boolean;
  id: string;
  label?: string;
  element?: JSX.Element;
  disabled?: boolean;
  name?: string;
  readOnly?: boolean;
  value?: string;
  inline?: boolean;
  isWrapped?: boolean;
  children?: React.ReactNode;
  dataQa?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

const Radio = (props: IRadio) => {
  const {
    disabled = false,
    id,
    label,
    element,
    isWrapped = false,
    readOnly = false,
    inline = false,
    dataQa,
  } = props;
  const { children, ...otherProps } = props;
  return (
    <>
      {!isWrapped && (
        <RadioContainer inline={inline}>
          <Label htmlFor={id} dataQa={`form-radio-${label?.split(' ')[0]}`}>
            {label}
          </Label>
          {element}
          <InputRadioField>
            <InputRadio
              type="radio"
              data-qa={dataQa}
              {...otherProps}
              disabled={disabled || readOnly}
            />
          </InputRadioField>
        </RadioContainer>
      )}
      {isWrapped && (
        <RadioContainer isWrapped={isWrapped}>
          <WordWrapBox>
            <Label htmlFor={id} dataQa={`form-radio-${label?.split(' ')[0]}`}>
              {label}
            </Label>
          </WordWrapBox>
          <div>
            <InputRadioField>
              <InputRadio
                type="radio"
                data-qa={dataQa}
                {...otherProps}
                disabled={disabled || readOnly}
              />
            </InputRadioField>
          </div>
        </RadioContainer>
      )}
    </>
  );
};

export default Radio;
