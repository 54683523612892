import React from 'react';
import StatusBadge from './StatusBadge';
import { color } from '../../config';

const AssignmentStatus = [
  {
    label: 'Not started',
    color: color.status.notStarted,
  },
  {
    label: 'Not started',
    color: color.status.notStarted,
  },
  {
    label: 'Ready for review',
    color: color.status.pending,
  },
  {
    label: 'Complete',
    color: color.status.complete,
  },
  {
    label: 'Returned',
    color: color.status.returned,
  },
  {
    label: 'Started',
    color: color.status.notStarted,
  },
];

export interface IAssignmentStatusBadge {
  status: number;
}

const AssignmentStatusBadge = ({ status }: IAssignmentStatusBadge) => (
  <StatusBadge color={AssignmentStatus[status].color}>
    {AssignmentStatus[status].label}
  </StatusBadge>
);

export default AssignmentStatusBadge;
