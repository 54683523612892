import styled, { css } from 'styled-components';
import { color } from '../../config';

export type IconButtonVariant = 'red' | 'blue' | 'white' | 'black' | 'reminder';

interface IconButtonContainerProps {
  variant: IconButtonVariant;
  disabled?: boolean;
}

export const IconButtonContainer = styled.button<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${color.light};
  border: none;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1rem;
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};

  & span {
    padding-left: 0.8rem;
    line-height: 1;
  }
`;

const IconButtonRed = css`
  background-color: ${color.error.background};
  color: ${color.error.foreground};
`;

const IconButtonDisabled = css`
  background-color: ${color.grey.lightest};
  color: ${color.grey.dark};
`;

const IconButtonWhite = css`
  background-color: ${color.primary.base};
  color: ${color.light};
`;

const IconButtonBlack = css`
  background-color: transparent;
  color: ${color.dark};
  &:hover {
    background-color: ${color.grey.lightest};
  }
`;

const IconButtonReminder = css`
  background-color: ${color.success.foreground};
  color: white;
`;

export const IconButtonIcon = styled.div<IconButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color.grey.lightest};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  color: ${color.primary.base};

  svg {
    display: block;
    width: 1.8rem;
  }

  ${({ variant, disabled }) => {
    if (disabled) {
      return IconButtonDisabled;
    }
    if (variant === 'red') {
      return IconButtonRed;
    }
    if (variant === 'white') {
      return IconButtonWhite;
    }
    if (variant === 'black') {
      return IconButtonBlack;
    }

    if (variant === 'reminder') {
      return IconButtonReminder;
    }

    return null;
  }}
`;
