import produce from 'immer';
import React, { SyntheticEvent, useContext, useState } from 'react';
import {
  EmployerContext,
  useCurrentSector,
  useCurrentSectorIndex,
} from '../../../context-providers/employer/employer-context';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';

export interface IEditSectorDetails {
  loadEmployer?: () => void;
}

const EditSectorDetails = ({ loadEmployer }: IEditSectorDetails) => {
  const closeModal = useCloseModal();
  const { state } = useContext(EmployerContext);

  const sector = useCurrentSector();
  const sectorIndex = useCurrentSectorIndex();
  const [sectorName, setSectorName] = useState(sector.name || '');

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (state.currentEmployer) {
      const payload = produce(state.currentEmployer, (draft) => {
        draft.sectors[sectorIndex].name = sectorName;
      });
      const res = await fetchAPI({
        method: 'PUT',
        body: payload,
        path: `EmployerRecords/${payload?.id || ''}`,
        responseType: 'text',
      });
      if (res.success) {
        loadEmployer && loadEmployer();
        closeModal();
      }
    }
  };

  // field errors
  const nameErr = validateField(sectorName, { required: true });
  const isValid = !nameErr;

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>Edit sector details</h2>
          <p>Please enter the basic details for the sector.</p>
        </Stack>
        <Stack stackMultiplier={0.5}>
          <TextInput
            label="Name"
            value={sectorName || ''}
            id="name"
            error={nameErr}
            dataQa="editSector-input-name"
            onChangeText={(str) => setSectorName(str)}
          />
        </Stack>
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="editSector-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            dataQa="editSector-btn-save"
          >
            Save details
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditSectorDetails;
