import { produce } from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { EmployerContext } from '../../../context-providers/employer/employer-context';
import { IEmployer } from '../../../context-providers/employer/employer-reducer';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import {
  IconArrowRight,
  IconClose,
  IconEdit,
  IconError,
  IconPlus,
  IconWarning,
} from '../../icons';
import { MaxLength } from '../../../context-providers/enums/global-enums';
import {
  CloseIconButton,
  EditEmployerContactLeftRightIcon,
  RadioWrapper,
} from '../ModalContent.style';
import Radio from '../../atoms/form-fields/radio/Radio';
import { tr } from 'date-fns/locale';

export interface IEditEmployerContact {
  loadEmployer?: () => void;
}

const EditEmployerContact = ({ loadEmployer }: IEditEmployerContact) => {
  const closeModal = useCloseModal();
  const { state } = useContext(EmployerContext);
  const [employer, setEmployer] = useState<IEmployer | null>(
    state.currentEmployer,
  );
  useEffect(() => setEmployer(state.currentEmployer), [state.currentEmployer]);

  const [originalEmailAddress, setOriginalEmailAddress] = useState(
    state.currentEmployer?.contact.email,
  );

  // This component is used when there has been no contact added when editing an employer so must default to the create mode
  const [step, setStep] = useState(
    !employer?.contact.email &&
      !employer?.contact.name &&
      !employer?.contact.jobTitle
      ? 2
      : 1,
  );
  const [contactOptionRadio, setContactOptionRadio] = useState(
    !employer?.contact.email &&
      !employer?.contact.name &&
      !employer?.contact.jobTitle
      ? 'new'
      : '',
  );

  const [originalContactEmpty] = useState(
    !employer?.contact.email &&
      !employer?.contact.name &&
      !employer?.contact.jobTitle
      ? true
      : false,
  );
  const [submitError, setSubmitError] = useState<string | null>(null);

  if (employer === null) {
    return null;
  }

  const handleChange = (value: string, fieldName: string) => {
    setEmployer(
      produce(employer, (newEmployer) => {
        set(newEmployer, fieldName, value);
        return newEmployer;
      }),
    );
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setSubmitError(null);

    if (contactOptionRadio === 'new') {
      const res = await fetchAPI({
        method: 'POST',
        body: employer,
        path: `EmployerRecords/updatecontact/${employer.id}`,
        responseType: 'text',
      });
      if (res.success) {
        loadEmployer && loadEmployer();
        closeModal();
      } else {
        setSubmitError(
          'Contact Update error - error creating the contact. Please try again.',
        );
      }
    } else {
      const res = await fetchAPI({
        method: 'PUT',
        body: employer,
        path: `EmployerRecords/${employer.id}`,
        responseType: 'text',
      });
      if (res.success) {
        loadEmployer && loadEmployer();
        closeModal();
      }
    }
  };

  // field errors
  const { contact } = employer;
  const nameErr = validateField(contact?.name, { required: true });
  const jobTitleErr = validateField(contact?.jobTitle, { required: true });
  const emailErr = validateField(contact?.email, {
    required: true,
    email: true,
  });

  const clearContactDetails = () => {
    contact.name = '';
    contact.jobTitle = '';
    contact.email = '';
  };

  const selectionMade =
    contactOptionRadio === 'new' ||
    contactOptionRadio === 'name' ||
    contactOptionRadio === 'email';
  const step1Valid = selectionMade;
  const isValid = !nameErr && !jobTitleErr && !emailErr;
  const step2valid = !nameErr && !jobTitleErr && !emailErr;

  return (
    <form onSubmit={handleSubmit} style={{ minWidth: 412 }}>
      {step === 1 && (
        <Stack stackMultiplier={2}>
          <Stack stackMultiplier={0.5}>
            <EditEmployerContactLeftRightIcon>
              <div>
                <IconEdit width={24} height={24} />
                <h2 data-qa="editemployercontactModal-header-create">
                  Edit Key Contact
                </h2>
              </div>
              <CloseIconButton
                aria-label="editemployercontactModal-cancel-x"
                data-qa="deleteForm-btn-cancel-icon"
                onKeyDown={(e) => e.key === 'Escape' && closeModal()}
                tabIndex={0}
                onClick={() => {
                  loadEmployer && loadEmployer();
                  closeModal();
                }}
              >
                <IconClose width={25} height={25} />
              </CloseIconButton>
            </EditEmployerContactLeftRightIcon>
          </Stack>

          <Stack stackMultiplier={1}>
            <p>
              The key contact is the individual at the employer who will receive
              the assigned forms.
              <br />
            </p>
            <RadioWrapper className="column">
              <Stack>
                <Radio
                  id="employer-key-contact"
                  label="Create new key contact"
                  dataQa="employer-key-contact-radio"
                  inline
                  checked={contactOptionRadio === 'new'}
                  onChange={() => {
                    setContactOptionRadio('new');
                  }}
                />
              </Stack>
              <Stack>
                <Radio
                  id="employer-contact-email"
                  label="Edit current contact email"
                  dataQa="employer-contact-email-radio"
                  inline
                  checked={contactOptionRadio === 'email'}
                  onChange={() => {
                    setContactOptionRadio('email');
                  }}
                />
              </Stack>
              <Stack>
                <Radio
                  id="employer-name"
                  label="Edit current contact name or job role"
                  dataQa="employer-name-radio"
                  inline
                  checked={contactOptionRadio === 'name'}
                  onChange={() => {
                    setContactOptionRadio('name');
                  }}
                />
              </Stack>
            </RadioWrapper>
          </Stack>
          <ButtonRow>
            <Button
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Edit employer contact modal - cancel',
                });
                closeModal();
              }}
              variant="secondary"
              dataQa="editemployercontactModal-btn-cancel"
            >
              Cancel
            </Button>
            <Button
              onClick={(e) => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Edit employer contact modal - Next',
                });
                e.preventDefault();
                if (contactOptionRadio === 'new') {
                  clearContactDetails();
                }
                setStep(2);
              }}
              disabled={!step1Valid}
              iconRight={<IconArrowRight responsive />}
              dataQa="editemployercontactModal-btn-next"
            >
              Next
            </Button>
          </ButtonRow>
        </Stack>
      )}
      {step === 2 && (
        <Stack stackMultiplier={2}>
          {contactOptionRadio === 'new' && (
            <Stack stackMultiplier={0.5}>
              <EditEmployerContactLeftRightIcon>
                <div>
                  <IconPlus width={24} height={24} />
                  <h2>Create New Key Contact</h2>
                </div>
                <CloseIconButton
                  aria-label="editemployercontactModal-cancel-x"
                  data-qa="deleteForm-btn-cancel-icon"
                  onKeyDown={(e) => e.key === 'Escape' && closeModal()}
                  tabIndex={0}
                  onClick={() => {
                    loadEmployer && loadEmployer();
                    closeModal();
                  }}
                >
                  <IconClose width={25} height={25} />
                </CloseIconButton>
              </EditEmployerContactLeftRightIcon>
              <br />
              <p>
                Create a new key contact to receive the Employers' assigned
                forms.
              </p>
              <ul>
                <li>
                  <b>
                    The current key contact will be soft deleted. They can only
                    <br />
                    be recovered by a member of the OneFile team.
                  </b>
                </li>
                <li>
                  <b>
                    Any forms or learners assigned to the current contact will{' '}
                    <br />
                    be reassigned to the new key contact.
                  </b>
                </li>
              </ul>
            </Stack>
          )}
          {contactOptionRadio === 'name' && (
            <Stack stackMultiplier={0.5}>
              <EditEmployerContactLeftRightIcon>
                <div>
                  <IconEdit width={24} height={24} />
                  <h2>Edit Key Contact Details</h2>
                </div>
                <CloseIconButton
                  aria-label="editemployercontactModal-cancel-x"
                  data-qa="deleteForm-btn-cancel-icon"
                  onKeyDown={(e) => e.key === 'Escape' && closeModal()}
                  tabIndex={0}
                  onClick={() => {
                    loadEmployer && loadEmployer();
                    closeModal();
                  }}
                >
                  <IconClose width={25} height={25} />
                </CloseIconButton>
              </EditEmployerContactLeftRightIcon>
              <br />
              <p>Edit the name and/or job role of the current key contact.</p>
            </Stack>
          )}
          {contactOptionRadio === 'email' && (
            <Stack stackMultiplier={0.5}>
              <EditEmployerContactLeftRightIcon>
                <div>
                  <IconEdit width={24} height={24} />
                  <h2>Edit Key Contact Email</h2>
                </div>
                <CloseIconButton
                  aria-label="editemployercontactModal-cancel-x"
                  data-qa="deleteForm-btn-cancel-icon"
                  onKeyDown={(e) => e.key === 'Escape' && closeModal()}
                  tabIndex={0}
                  onClick={() => {
                    loadEmployer && loadEmployer();
                    closeModal();
                  }}
                >
                  <IconClose width={25} height={25} />
                </CloseIconButton>
              </EditEmployerContactLeftRightIcon>
              <br />
              <p>
                Edit the email used to receive the Employers' assigned forms.
              </p>
              <p>
                <b>For technical and GDPR reasons:</b>
              </p>
              <ul>
                <li>
                  <b>
                    A new keychain and account will be created using the <br />
                    updated email address.
                  </b>
                </li>
                <li>
                  <b>
                    The current contact will be deleted. The account <br />
                    cannot be recovered.
                  </b>
                </li>
                <li>
                  <b>
                    Any forms or learners assigned to the current contact will
                    be <br />
                    reassigned to the new key contact email.
                  </b>
                </li>
              </ul>
            </Stack>
          )}
          <Stack stackMultiplier={0.5}>
            <TextInput
              label="Contact name"
              value={contact?.name}
              id="name"
              error={nameErr}
              dataQa="editContact-input-name"
              onChangeText={(str) => handleChange(str, 'contact.name')}
              disabled={contactOptionRadio === 'email'}
            />
            <TextInput
              label="Job role/position"
              value={contact?.jobTitle}
              id="jobTitle"
              error={jobTitleErr}
              dataQa="editContact-input-job"
              onChangeText={(str) => handleChange(str, 'contact.jobTitle')}
              disabled={contactOptionRadio === 'email'}
            />
            <TextInput
              label="Email address"
              value={contact?.email}
              id="email"
              maxLength={MaxLength.EmailAddress}
              error={emailErr}
              dataQa="editContact-input-email"
              onChangeText={(str) => handleChange(str, 'contact.email')}
              disabled={contactOptionRadio === 'name'}
            />
            {submitError && (
              <p>
                <IconError></IconError> {submitError}
              </p>
            )}

            {originalEmailAddress != contact.email &&
              contactOptionRadio === 'email' && (
                <p style={{ display: 'flex' }}>
                  <IconError></IconError> Changing the email address will create
                  a new keychain account and remove the account from the
                  previous one.
                </p>
              )}
          </Stack>
          <ButtonRow>
            {contactOptionRadio === 'new' && !originalContactEmpty && (
              <Button
                onClick={() => {
                  loadEmployer && loadEmployer();
                  setStep(1);
                }}
                variant="secondary"
                dataQa="editContact-btn-back"
              >
                Back
              </Button>
            )}
            {(contactOptionRadio === 'email' ||
              contactOptionRadio === 'name') && (
              <Button
                onClick={() => {
                  loadEmployer && loadEmployer();
                  setStep(1);
                }}
                variant="secondary"
                dataQa="editContact-btn-back"
              >
                Back
              </Button>
            )}
            {contactOptionRadio === 'new' && (
              <Button
                type="submit"
                disabled={!step2valid}
                dataQa="editContact-btn-save"
                iconLeft={<IconPlus responsive />}
              >
                Create key contact
              </Button>
            )}
            {contactOptionRadio === 'name' && (
              <Button
                type="submit"
                disabled={!step2valid}
                dataQa="editContact-btn-save"
                iconLeft={<IconEdit responsive />}
              >
                Edit key contact details
              </Button>
            )}
            {contactOptionRadio === 'email' && (
              <Button
                type="submit"
                disabled={!step2valid}
                dataQa="editContact-btn-save"
                iconLeft={<IconEdit responsive />}
              >
                Edit key contact email
              </Button>
            )}
          </ButtonRow>
        </Stack>
      )}
    </form>
  );
};

export default EditEmployerContact;
