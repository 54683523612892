import React from 'react';
import Employer from '../../components/organisms/Employer/Employer';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import AuthWrapper from '../../components/layout/AuthWrapper';

const EmployerPage = () => {
  return (
    <AuthWrapper>
      <Head title="Employer" />
      <Layout
        section="employers"
        breadcrumbs={[
          { link: '/', label: 'Home' },
          { link: '/list-employers', label: 'Employers' },
          { label: 'Edit employer' },
        ]}
      >
        <Employer />
      </Layout>
    </AuthWrapper>
  );
};

export default EmployerPage;
