import { IEnrolmentStatus } from '../context-providers/learner/learner-reducer';
import { fetchAPI } from './fetch-api';

export interface ILearnerCentre {
  id: number;
  name: string;
}

export interface ILearnerPlacement {
  id: number;
  name: string;
}

export interface ILearnerClassroom {
  id: number;
  name: string;
}

export interface ILearnerAssessor {
  id: number;
  firstName: string;
  surname: string;
}

const ExportLearnerHelper = {
  getCentres: () => {
    return fetchAPI<ILearnerCentre[]>({
      path: 'ReferenceData/Centres',
    }).then((response) => {
      if (response.success) {
        return response.body;
      }
      return Promise.reject();
    });
  },
  getEnrolmentStatuses: () => {
    return fetchAPI<IEnrolmentStatus[]>({
      path: 'learners/enrolmentStatus',
    }).then((response) => {
      if (response.success) {
        return response.body;
      }
      return Promise.reject();
    });
  },
  getPlacements: (centreId: string | number) => {
    return fetchAPI<ILearnerClassroom[]>({
      path: 'ReferenceData/Placements',
      urlParams: { centreId: centreId.toString() },
    }).then((response) => {
      if (response.success) {
        return response.body;
      }
      return Promise.reject();
    });
  },
  getClassrooms: (centreId: number) => {
    return fetchAPI<ILearnerClassroom[]>({
      path: 'ReferenceData/Classrooms',
      urlParams: { centreId: centreId.toString() },
    }).then((response) => {
      if (response.success) {
        return response.body;
      }
      return Promise.reject();
    });
  },
  getAssessors: (centreId: number) => {
    return fetchAPI<ILearnerAssessor[]>({
      path: 'ReferenceData/assessors',
      urlParams: { centreId: centreId.toString() },
    }).then((response) => {
      if (response.success) {
        return response.body;
      }
      return Promise.reject();
    });
  },
};
export default ExportLearnerHelper;
