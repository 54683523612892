import styled, { css } from 'styled-components';
import { boxShadow, color } from '../../../config';

export interface InputRadioProps {
  invalid?: boolean;
  disabled?: boolean;
  isWrapped?: boolean;
}
export interface RadioContainerProps {
  inline?: boolean;
  isWrapped?: boolean;
}

export const InputRadioField = styled.div`
  position: relative;
  display: inline-flex;
`;

export const InputRadio = styled.input<InputRadioProps>`
  appearance: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-size: 1em;
  position: relative;
  color: ${color.grey.dark};
  border-radius: 1em;
  border: ${(props) =>
    props.invalid
      ? `2px solid ${color.error.foreground}`
      : `2px solid ${color.grey.light}`};
  opacity: ${(props) => (props.disabled ? `0.8` : undefined)};
  transition: all 0.15s linear;
  margin-top: ${(props) => (props.isWrapped ? '2rem' : 0)};

  :checked {
    border-color: ${color.primary.base};

    ::after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 1rem;
      width: 1rem;
      border-radius: 1rem;
      background-color: ${color.primary.base};
    }
    :focus {
      border-color: ${color.primary.base};
    }
  }

  :focus {
    outline: none;
    border-color: ${color.grey.base};
    border: ${(props) =>
      props.invalid
        ? `2px solid ${color.error.foreground}`
        : `2px solid ${color.grey.light}`};
    box-shadow: ${(props) =>
      props.invalid ? boxShadow.error : boxShadow.primary};
  }

  :disabled {
    ${(props) => {
      if (!props.readOnly) {
        return InputRadioDisabled;
      }
      return null;
    }}
  }
`;

const InputRadioDisabled = css`
  background-color: ${color.grey.lightest};
  border-width: 1px;
`;

export const RadioInline = css`
  display: inline-flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: 3rem;

  label {
    font-weight: 200;
    font-size: 1.7rem;
    margin-top: -6px; // todo overall improve styling of radio
    margin-left: 1.2rem;
  }
`;

export const RadioNotInline = css`
  text-align: left;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  label {
    width: 35rem;
    font-weight: 200;
    font-size: 1.7rem;
    margin-left: 1.2rem;
    margin-top: 0.625rem;
  }
`;

export const RadioContainer = styled.div<RadioContainerProps>`
  ${(props) => {
    if (props.inline) {
      return RadioInline;
    }
    if (props.isWrapped) {
      return RadioNotInline;
    }
    return css`
      display: flex;
    `;
  }};
`;

export const RadioWithIconWrapper = styled.div`
  align-items: center;
  border: 2px solid #f1f2f4;
  padding: 2.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 0.8rem;
  margin-top: 0.8rem;

  & svg {
    margin-left: 1.6rem;
    margin-right: 0.8rem;
  }

  & label {
    font-family: Open Sans;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    letter-spacing: 0em;
    text-align: left;
  }
`;
