import { TIconType } from '../../components/icons/Icon';
import { TWorkflowStageType } from './form-types';

export enum StageId {
  LEARNER = 1,
  EMPLOYER = 2,
  PROVIDER = 3,
  APPROVAL = 4,
  ENQUIRY = 5,
}

export enum TypeId {
  LEARNERSONLY = 1,
  LEARNERSEMPLOYERS = 2,
  TRAININGPROVIDERSLEARNERSEMPLOYERS = 3,
  LEARNERENQUIRY = 4,
  CUSTOM = 5,
}

export type TWorkflowStageSpec = {
  label: string;
  icon: TIconType;
  type: TWorkflowStageType;
  text: string;
  stageId: StageId;
  editable: boolean;
  hidden?: boolean;
};

type TWorkflowStages = TWorkflowStageSpec[];

export const blankStages: TWorkflowStages = [
  {
    type: 'Learner',
    label: 'Learner',
    text: 'Send to Learner',
    icon: 'IconUser',
    stageId: 1,
    editable: true,
  },
  {
    type: 'Employer',
    label: 'Employer',
    text: 'Send to Employer',
    icon: 'IconPortfolio',
    stageId: 2,
    editable: true,
  },
  {
    type: 'TrainingProvider',
    label: 'Training Provider',
    text: 'Send to Training Provider',
    icon: 'IconLearningManager',
    stageId: 3,
    editable: false,
  },
  {
    type: 'Approval',
    label: 'Training Provider Approval',
    text: 'Training Provider Approval',
    icon: 'IconSignature',
    stageId: 4,
    editable: false,
  },
  {
    type: 'Enquiry',
    label: 'Learner Enquiry',
    text: 'Send to Enquiry',
    icon: 'IconQuestionMark',
    stageId: 5,
    editable: false,
    hidden: true,
  },
];
