import { produce } from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useContext, useState } from 'react';
import {
  EmployerContext,
  useCurrentSector,
  useCurrentSectorIndex,
} from '../../../context-providers/employer/employer-context';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { MaxLength } from '../../../context-providers/enums/global-enums';

export interface IEditSectorContact {
  loadEmployer?: () => void;
}

const EditSectorContact = ({ loadEmployer }: IEditSectorContact) => {
  const closeModal = useCloseModal();
  const { state } = useContext(EmployerContext);

  const currentSector = useCurrentSector();
  const [sector, setSector] = useState(currentSector);
  const sectorIndex = useCurrentSectorIndex();

  const handleChange = (value: string, fieldName: string) => {
    setSector(
      produce(sector, (newSector) => {
        if (newSector) {
          set(newSector, fieldName, value);
        }
        return newSector;
      }),
    );
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (state.currentEmployer) {
      const payload = produce(state.currentEmployer, (draft) => {
        draft.sectors[sectorIndex].contact = {
          name: sector?.contact?.name || '',
          email: sector?.contact?.email || '',
          jobTitle: sector?.contact?.jobTitle || '',
        };
      });
      const res = await fetchAPI({
        method: 'PUT',
        body: payload,
        path: `EmployerRecords/${payload?.id || ''}`,
        responseType: 'text',
      });
      if (res.success) {
        loadEmployer && loadEmployer();
        closeModal();
      }
    }
  };

  // field errors
  const { contact } = sector || {};
  const nameErr = validateField(contact?.name, { required: true });
  const jobTitleErr = validateField(contact?.jobTitle, { required: true });
  const emailErr = validateField(contact?.email, {
    required: true,
    email: true,
  });
  const isValid = !nameErr && !jobTitleErr && !emailErr;

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>Edit sector contact</h2>
          <p>
            Please enter sector contact information for somebody at the
            organisation.
          </p>
        </Stack>
        <Stack stackMultiplier={1}>
          <TextInput
            label="Contact name"
            value={contact?.name || ''}
            id="name"
            error={nameErr}
            dataQa="editSectorContact-input-name"
            onChangeText={(str) => handleChange(str, 'contact.name')}
          />
          <TextInput
            label="Job role/position"
            value={contact?.jobTitle || ''}
            id="jobTitle"
            error={jobTitleErr}
            dataQa="editSectorContact-input-job"
            onChangeText={(str) => handleChange(str, 'contact.jobTitle')}
          />
          <TextInput
            label="Email address"
            value={contact?.email || ''}
            id="email"
            maxLength={MaxLength.EmailAddress}
            error={emailErr}
            dataQa="editSectorContact-input-email"
            onChangeText={(str) => handleChange(str, 'contact.email')}
          />
        </Stack>
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="editSectorContact-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            dataQa="editSectorContact-btn-save"
          >
            Save details
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditSectorContact;
