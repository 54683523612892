import React from 'react';
import ReactGA from '../../../utils/google-analytics';
import {
  useCurrentEmployer,
  useCurrentSector,
} from '../../../context-providers/employer/employer-context';
import { usePagedRequest } from '../../../utils/paged-request';
import { formatDate } from '../../../utils/dates';
import AssignmentStatusBadge from '../../atoms/status-badge/AssignmentStatusBadge';
import Stack from '../../atoms/stack/Stack';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import Table from '../../atoms/table/Table';
import Paging from '../../atoms/paging/Paging';
import { PagedFormAssignment } from '../../../context-providers/forms/paged-form-assignment';
import { FormAssignmentStatus } from '../../../context-providers/forms/form-assignment-status';
import { TableActions } from '../../atoms/table/Table.style';
import { Link } from 'gatsby';
import { IconDelete, IconDownload, IconShow } from '../../icons';
import configLoader from '../configuration/configuration-loader';
import { fetchAPI } from '../../../utils/fetch-api';
import { LinkButton } from '../../atoms/button/Button';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { userHasPermission } from '../../../context-providers/user/user-permission';
import { Permission } from '../../../context-providers/enums/global-enums';
import { getUserClaims } from '../../../utils/jwt-decode';
import { StageId } from '../../../context-providers/forms/form-workflows';
import DownloadForm from '../../modals/forms/DownloadForm';
import DeleteData from '../../modals/forms/DeleteData';

const EditSectorTasks = () => {
  const user = getUserClaims();
  let canDeleteReviewForm: boolean = false;

  if (user) {
    canDeleteReviewForm = userHasPermission(
      user.permissions,
      Permission.DeleteReviewForm,
    );
  }

  const employer = useCurrentEmployer();
  const sector = useCurrentSector();
  const showModal = useShowModal();
  const config = configLoader();
  const reviewForms = usePagedRequest<PagedFormAssignment>(
    'v2/forms/search/formassignments',
    {
      sortColumnName: 'lastModified',
      learnerId: '',
      employerId: employer?.id || '',
      sectorId: sector?.id || '',
    },
  );

  const onConfirmDelete = async (formAssignment: string | null) => {
    const res = await fetchAPI<string>({
      path: `v2/forms/delete/${formAssignment}`,
      method: 'DELETE',
      responseType: 'text',
    });
    if (res.success) {
      window.location.reload();
    }
  };

  const renderRow = (data: PagedFormAssignment) => [
    data.formName || '-',
    data.learnerName || '-',
    formatDate(data.employerDateSent) || '-',
    <AssignmentStatusBadge
      status={
        data.employerFormAssignmentStatus === FormAssignmentStatus.NULL
          ? FormAssignmentStatus.NotStarted
          : data.employerFormAssignmentStatus
      }
      key="1"
    />,
    <TableActions key="4">
      {data.overallAssignmentStatus === FormAssignmentStatus.Completed ? (
        <Link to={`/review-completed-form/?fid=${data.id}`}>
          <IconShow responsive />
          <span className="sr-only">View completed form</span>
        </Link>
      ) : (
        ' '
      )}

      {data.overallAssignmentStatus === FormAssignmentStatus.Completed &&
      config.EnablePdfExport ? (
        <button
          type="button"
          data-qa={`forms-btn-download-${
            data?.formName?.replace(/\s/g, '-') ?? 'empty'
          }`}
          onClick={() =>
            showModal(DownloadForm, {
              formId: data.id,
            })
          }
        >
          <IconDownload responsive />
          <span className="sr-only">Download {data.id} form</span>
        </button>
      ) : (
        ' '
      )}

      {data.overallAssignmentStatus === FormAssignmentStatus.NotStarted &&
        (data.learnerFormAssignmentStatus === FormAssignmentStatus.NULL ||
          data.learnerFormAssignmentStatus ===
            FormAssignmentStatus.NotStarted) &&
        (data.trainingProviderFormAssignmentStatus ===
          FormAssignmentStatus.NULL ||
          data.trainingProviderFormAssignmentStatus ===
            FormAssignmentStatus.NotStarted) &&
        (data.employerFormAssignmentStatus === FormAssignmentStatus.NULL ||
          data.employerFormAssignmentStatus ===
            FormAssignmentStatus.NotStarted) && (
          <button
            type="button"
            data-qa={`forms-btn-delete-${
              data?.formName?.replace(/\s/g, '-') ?? 'empty'
            }`}
            onClick={() =>
              showModal(DeleteData, {
                onConfirmDelete: () => {
                  onConfirmDelete(data.id);
                },
              })
            }
            disabled={!canDeleteReviewForm}
          >
            <IconDelete fill={!canDeleteReviewForm ? '#ccc' : ''} responsive />
            <span className="sr-only">Delete {data.formName} form</span>
          </button>
        )}
    </TableActions>,
    data.overallAssignmentStatus === FormAssignmentStatus.NotStarted &&
    data.currentStageId === StageId.PROVIDER ? (
      <LinkButton
        to={`/customer-form/?fid=${data.id}`}
        dataQa={`customer-form-${data.formName}`}
        id={data.id}
      >
        Continue
      </LinkButton>
    ) : data.currentStageId === StageId.LEARNER ? (
      <LinkButton
        key="3"
        to={`/send-learner-form/?fid=${data.id}`}
        dataQa={`send-learner-form-${data.formName}`}
        id={data.id}
      >
        Send Mail
      </LinkButton>
    ) : data.learnerFormAssignmentStatus === FormAssignmentStatus.Rejected ? (
      <LinkButton
        key="3"
        to={`/send-learner-reject-form/?fid=${data.id}`}
        dataQa={`send-learner-reject-form-${data.formName}`}
        id={data.id}
      >
        Send Mail
      </LinkButton>
    ) : data.currentStageId === StageId.EMPLOYER ? (
      <LinkButton
        to={`/send-employer-form/?fid=${data.id}`}
        dataQa={`send-employer-form-${data.formName}`}
        id={data.id}
      >
        Send Mail
      </LinkButton>
    ) : data.learnerFormAssignmentStatus ===
      FormAssignmentStatus.ReadyForReview ? (
      <LinkButton
        id={data.id}
        to={`/review-learner-form/?fid=${data.id}`}
        dataQa={`review-form-${data.formName}`}
        onClick={() => {
          ReactGA.event({
            category: 'Button',
            action: 'Review forms - review and approve',
          });
        }}
      >
        Review
      </LinkButton>
    ) : data.employerFormAssignmentStatus ===
      FormAssignmentStatus.ReadyForReview ? (
      <LinkButton
        id={data.id}
        to={`/review-employer-form/?fid=${data.id}`}
        dataQa={`review-form-${data.formName}`}
        onClick={() => {
          ReactGA.event({
            category: 'Button',
            action: 'Review forms - review and approve',
          });
        }}
      >
        Review
      </LinkButton>
    ) : (
      ' '
    ),
  ];

  return (
    <>
      <Stack stackMultiplier={2}>
        <GridFlex>
          <GridFlexItem small={6}>
            <Stack stackMultiplier={0.5}>
              <h2>Tasks</h2>
              <p>
                You can view any outstanding forms which need to be completed by
                the employer.
              </p>
            </Stack>
          </GridFlexItem>
        </GridFlex>
        <Table
          caption="List of employer tasks"
          data={reviewForms.data}
          headerItems={[
            { sortColumnName: 'formName', label: 'Form name' },
            { sortColumnName: 'learnerName', label: 'Learner' },
            { sortColumnName: 'dateSent', label: 'Date added' },
            { sortColumnName: 'status', label: 'Status' },
            { label: 'Actions' },
            { label: '' },
          ]}
          renderRow={renderRow}
          paged={reviewForms.pagedParams}
        />
        <Paging pageCount={reviewForms.pageCount} />
      </Stack>
    </>
  );
};

export default EditSectorTasks;
