import React from 'react';
import ReactTooltip from 'react-tooltip';
import {
  useCurrentEmployer,
  useCurrentSector,
} from '../../../context-providers/employer/employer-context';
import { ScreenActions } from './Employer.style';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import { color } from '../../config';
import { IconDelete, IconEdit } from '../../icons';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import IconButton from '../../atoms/icon-button/IconButton';
import DataCard, { DataCardItem } from '../../atoms/data-card/DataCard';
import Stack from '../../atoms/stack/Stack';
import { useEmployer } from '../../../context-providers/employer/employer-hooks';
import DeleteSector from '../../modals/employer/DeleteSector';
import EditSectorDetailsModal from '../../modals/employer/EditSectorDetails';
import EditSectorContact from '../../modals/employer/EditSectorContact';
import EditSectorCourseDetails from '../../modals/employer/EditSectorCourseDetails';

const EditSectorDetails = () => {
  const sector = useCurrentSector();
  const employer = useCurrentEmployer();
  const showModal = useShowModal();
  const { loadEmployer } = useEmployer();

  const cannotDisableError = sector.learners.length
    ? 'Sectors with assigned learners cannot be deleted'
    : employer && employer.sectors.length <= 1
    ? 'Employers must have at least one sector'
    : '';

  const { name: contactName, jobTitle, email } = sector?.contact || {};
  const { name: courseName } = sector?.course || {};

  return (
    <>
      <ReactTooltip effect="solid" backgroundColor={color.grey.base} />
      <ScreenActions>
        <span
          data-tip={cannotDisableError}
          data-tip-disable={!cannotDisableError}
        >
          <IconButton
            variant="red"
            icon={<IconDelete responsive />}
            onClick={() => {
              showModal(DeleteSector, { loadEmployer });
            }}
            disabled={!!cannotDisableError}
            dataQa="employer-btn-delete-sector"
          >
            Delete sector
          </IconButton>
        </span>
        <IconButton
          icon={<IconEdit responsive />}
          dataQa="employer-btn-edit-sector"
          onClick={() => {
            showModal(EditSectorDetailsModal, { loadEmployer });
          }}
        >
          Edit sector details
        </IconButton>
      </ScreenActions>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>{sector.name} / Details</h2>
          <p>
            You can edit details about this sector and assign the relevant
            course.
          </p>
        </Stack>
        <GridFlex>
          <GridFlexItem small={5}>
            <DataCard
              title="Sector Contact"
              onEdit={() => {
                showModal(EditSectorContact, { loadEmployer });
              }}
              isEmpty={!contactName && !jobTitle && !email}
              dataQa="employer-btn-edit-sector-contact"
            >
              <DataCardItem
                required
                hideLabel
                label="Contact name"
                value={contactName}
                dataQa="employer-lbl-sector-contact"
              />
              <DataCardItem
                required
                hideLabel
                label="Job title"
                value={jobTitle}
                dataQa="employer-lbl-sector-job"
              />
              <DataCardItem
                required
                hideLabel
                label="Email"
                value={email}
                dataQa="employer-lbl-sector-email"
              />
            </DataCard>
          </GridFlexItem>
          <GridFlexItem small={5}>
            <DataCard
              title="Course Details"
              onEdit={() => {
                showModal(EditSectorCourseDetails, { loadEmployer });
              }}
              isEmpty={!courseName}
              dataQa="employer-btn-edit-sector-course"
            >
              <DataCardItem
                required
                hideLabel
                label="Course name"
                value={courseName}
                dataQa="employer-lbl-sector-course"
              />
            </DataCard>
          </GridFlexItem>
        </GridFlex>
      </Stack>
    </>
  );
};

export default EditSectorDetails;
