import produce from 'immer';
import set from 'lodash/set';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { EmployerContext } from '../../../context-providers/employer/employer-context';
import { IEmployer } from '../../../context-providers/employer/employer-reducer';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Label from '../../atoms/form-fields/label/Label';
import Radio from '../../atoms/form-fields/radio/Radio';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';
import { Wrapper } from '../ModalContent.style';
import ExportLearnerHelper, {
  ILearnerCentre,
  ILearnerPlacement,
} from '../../../utils/exportLearnerHelper';
import { FilterItem } from '../../atoms/filter/models/filter-item';
import FilterSingleItem from '../../atoms/filter/FilterSingleItem';
import Select, { IOption } from '../../atoms/form-fields/select/Select';

export interface IEditEmployerDetails {
  loadEmployer?: () => void;
  centres: ILearnerCentre[];
}

const EditEmployerDetails = ({
  loadEmployer,
  centres,
}: IEditEmployerDetails) => {
  const closeModal = useCloseModal();
  const { state } = useContext(EmployerContext);

  const [employer, setEmployer] = useState<IEmployer | null>(
    state.currentEmployer,
  );
  useEffect(() => setEmployer(state.currentEmployer), [state.currentEmployer]);

  const [placements, setPlacements] = useState<ILearnerPlacement[]>([]);
  const [placementsLoading, setPlacementsLoading] = useState<boolean>(false);
  const [placementsLoaded, setPlacementsLoaded] = useState<boolean>(false);
  const getPlacements = (): void => {
    if (hasCentreOptions && !placementsLoaded && !placementsLoading) {
      const centreOption = centreOptions.find(
        (c) => c.value === employer?.onefileOrganisationId.toString(),
      );
      if (centreOption !== undefined) {
        setPlacementsLoading(true);
        ExportLearnerHelper.getPlacements(centreOption.value.toString()).then(
          (response) => {
            setPlacements(response);
            setPlacementsLoaded(true);
            setPlacementsLoading(false);
          },
        );
      }
    }
  };
  useEffect(() => {
    getPlacements();
  }, [getPlacements]);

  if (employer === null) {
    return null;
  }

  const hasCentreOptions: boolean = centres.length > 0;
  const hasPlacementOptions: boolean = placements.length > 0;
  const hasEitherCentreOrPlacementOptions: boolean =
    centres.length > 0 || placements.length > 0;

  const centreOptions: IOption[] = centres.map((i) => ({
    value: i.id.toString(),
    label: i.name,
  }));

  const placementOptions: IOption[] = placements.map((i) => ({
    value: i.id.toString(),
    label: i.name,
  }));

  const currentCentreOption: IOption | undefined = centreOptions.find(
    (c) => c.value === employer.onefileOrganisationId.toString(),
  );

  const currentPlacementOption: IOption | undefined = placementOptions.find(
    (p) => p.value === employer.onefilePlacementId.toString(),
  );

  const handleCentreChange = (value: string) => {
    let selectedId: number = parseInt(value);
    if (centres.find((c) => c.id === selectedId) === undefined) {
      selectedId = 0;
    }

    setEmployer(
      produce(employer, (newEmployer) => {
        setPlacementsLoaded(false);
        getPlacements();
        set(newEmployer, 'onefileOrganisationId', selectedId);
        set(newEmployer, 'onefilePlacementId', 0);

        return newEmployer;
      }),
    );
  };

  const handlePlacementChange = (value: string) => {
    let selectedId: number = parseInt(value);
    if (placements.find((p) => p.id === selectedId) === undefined) {
      selectedId = 0;
    }

    setEmployer(
      produce(employer, (newEmployer) => {
        set(newEmployer, 'onefilePlacementId', selectedId);
        return newEmployer;
      }),
    );
  };

  const handleEmployerChange = (
    value: string | boolean | number,
    fieldName: string,
  ) => {
    setEmployer(
      produce(employer, (newEmployer) => {
        set(newEmployer, fieldName, value);
        return newEmployer;
      }),
    );
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    let payload = employer;
    const selectedPlacement = placements.find(
      (p) => p.id === employer.onefilePlacementId,
    );
    if (selectedPlacement)
      payload = { ...employer, name: selectedPlacement.name };

    const res = await fetchAPI({
      method: 'PUT',
      body: payload,
      path: `EmployerRecords/${employer.id}`,
      responseType: 'text',
    });
    if (res.success) {
      loadEmployer && loadEmployer();
      closeModal();
    }
  };

  // field errors
  const nameErr = validateField(employer.name, {
    required: !currentPlacementOption,
  });
  const centreErr = hasCentreOptions && employer.onefileOrganisationId === 0;
  const placementErr =
    hasPlacementOptions &&
    currentPlacementOption &&
    employer.onefilePlacementId === 0;

  const ernNumberErr = validateField(employer.edsNumber, { required: false });

  const isValid =
    !nameErr &&
    !centreErr &&
    !placementErr &&
    (!ernNumberErr || ernNumberErr === '');

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 412 }}>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>Edit Employer</h2>
        </Stack>
        {hasEitherCentreOrPlacementOptions && (
          <Stack dataQa="employerModal-select-centre">
            <h3>Eportfolio Centre</h3>
            <p>
              The Eortfolio centre is required for any relevant event
              subscriptions to process as expected.
            </p>
            {hasCentreOptions && (
              <Select
                id="centre-select"
                dataQa="centre-select"
                placeholder="Select centre"
                options={centreOptions}
                initalValue={currentCentreOption}
                onOptionChanged={(centerOption) =>
                  handleCentreChange(centerOption.value)
                }
              />
            )}
          </Stack>
        )}
        <Stack stackMultiplier={1}>
          {!currentPlacementOption && (
            <TextInput
              label="Employer name"
              value={employer.name || ''}
              id="name"
              error={nameErr}
              dataQa="editEmployer-input-name"
              onChangeText={(str) => handleEmployerChange(str, 'name')}
            />
          )}
          {hasPlacementOptions &&
            !placementsLoading &&
            currentPlacementOption && (
              <Select
                id="placement-filter"
                dataQa="placement-filter"
                options={placementOptions}
                initalValue={currentPlacementOption}
                label="Employer placement"
                placeholder="Select placement"
                onOptionChanged={(placementOption) =>
                  handlePlacementChange(placementOption.value)
                }
              />
            )}
          <Stack stackMultiplier={0.5}>
            <Label htmlFor="levy">Levy or Non-levy</Label>
            <Wrapper>
              <Radio
                id="levy"
                name="levy"
                value="true"
                label="Levy"
                inline
                checked={employer.levy}
                dataQa="editEmployer-radio-levy"
                onChange={() => handleEmployerChange(true, 'levy')}
              />
              <Radio
                id="nonLevy"
                name="levy"
                value="false"
                inline
                label="Non-levy"
                checked={!employer.levy}
                dataQa="editEmployer-radio-non-levy"
                onChange={() => handleEmployerChange(false, 'levy')}
              />
            </Wrapper>
          </Stack>
          <TextInput
            label="ERN"
            value={employer.edsNumber || ''}
            id="edsNumber"
            dataQa="editEmployer-input-ern"
            onChangeText={(str) => handleEmployerChange(str, 'edsNumber')}
            error={ernNumberErr}
          />
        </Stack>
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="editEmployer-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            dataQa="editEmployer-btn-save"
          >
            Save details
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditEmployerDetails;
