import React, { ReactNode } from 'react';
import IconButton from '../icon-button/IconButton';
import { IconEdit, IconError, IconPlus } from '../../icons';
import {
  DataCardContainer,
  DataCardAddDetails,
  DataCardContent,
  DataCardIcon,
  DataCardTitle,
  DataCardItemError,
  DataCardItemContainer,
} from './DataCard.style';
import Stack from '../stack/Stack';

export interface IDataCardItem {
  label?: string;
  required?: boolean;
  value: string | undefined | null;
  hideLabel?: boolean;
  dataQa?: string;
}

export interface IDataCard {
  title: string;
  onEdit?: () => void;
  hasBackground?: boolean;
  isEmpty?: boolean;
  children?: ReactNode;
  dataQa?: string;
}

export const DataCardItem = ({
  label,
  required,
  value,
  hideLabel,
  dataQa,
}: IDataCardItem) => {
  const isEmpty = required && !value;
  return isEmpty ? (
    <DataCardItemError>
      <IconError />
      {label} is required
    </DataCardItemError>
  ) : (
    <DataCardItemContainer data-qa={dataQa}>
      {!hideLabel && (
        <>
          <strong>{label}</strong>:&nbsp;
        </>
      )}
      {value || '-'}
    </DataCardItemContainer>
  );
};

const DataCard = ({
  title,
  onEdit = () => {},
  hasBackground = false,
  children,
  isEmpty = false,
  dataQa,
}: IDataCard) => {
  return (
    <DataCardContainer hasBackground={hasBackground} isEmpty={isEmpty}>
      <DataCardTitle>
        <h3>{title}</h3>
        {!isEmpty && (
          <DataCardIcon onClick={() => onEdit()} data-qa={dataQa}>
            <IconEdit />
            <span className="sr-only">Edit {title}</span>
          </DataCardIcon>
        )}
      </DataCardTitle>
      {isEmpty ? (
        <DataCardAddDetails>
          <IconButton
            icon={<IconPlus responsive />}
            onClick={() => onEdit()}
            data-qa={dataQa}
          >
            Add details
          </IconButton>
        </DataCardAddDetails>
      ) : (
        <DataCardContent>
          <Stack stackMultiplier={0.5}>{children}</Stack>
        </DataCardContent>
      )}
    </DataCardContainer>
  );
};

export default DataCard;
