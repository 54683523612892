import { produce } from 'immer';
import React, { SyntheticEvent, useContext } from 'react';
import {
  EmployerContext,
  useCurrentSectorIndex,
} from '../../../context-providers/employer/employer-context';
import { IEmployer } from '../../../context-providers/employer/employer-reducer';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Stack from '../../atoms/stack/Stack';
import { IconDelete } from '../../icons';

export interface IDeleteSector {
  loadEmployer?: () => void;
}

const DeleteSector = ({ loadEmployer }: IDeleteSector) => {
  const closeModal = useCloseModal();

  const sectorIndex = useCurrentSectorIndex();
  const { state } = useContext(EmployerContext);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    const payload = produce(state.currentEmployer, (newEmployer) => {
      if (newEmployer) {
        newEmployer.sectors.splice(sectorIndex, 1);
      }
    });
    const res = await fetchAPI<IEmployer>({
      method: 'PUT',
      body: payload,
      path: `EmployerRecords/${payload?.id || ''}`,
      responseType: 'text',
    });
    if (res.success) {
      loadEmployer && loadEmployer();
      closeModal();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <h2>Are you sure?</h2>
        <p>Records can&apos;t be recovered once deleted.</p>
        <ButtonRow>
          <Button
            variant="subtle"
            dataQa="deleteSector-btn-cancel"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="warn"
            dataQa="deleteSector-btn-delete"
            iconRight={<IconDelete responsive />}
          >
            Delete
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default DeleteSector;
