import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from '../../../utils/google-analytics';
import { useShowModal } from '../../../context-providers/ui/ui-hooks';
import DataCard, { DataCardItem } from '../../atoms/data-card/DataCard';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import GridFlexItem from '../../atoms/grid-flex/GridFlexItem';
import { FullWidthContainer } from '../../layout/Layout.style';
import { Header, Tag, TitleWithTag } from './Employer.style';
import Tabbed from '../../atoms/tabbed/Tabbed';
import SideTabs, { ISideTab } from '../../atoms/tabbed/SideTabs';
import EditSectorDetails from './EditSectorDetails';
import EditSectorLearners from './EditSectorLearners';
import EditSectorTasks from './EditSectorTasks';
import { formatDateTime } from '../../../utils/dates';
import { combineAddress } from '../../../utils/address';
import { useEmployer } from '../../../context-providers/employer/employer-hooks';
import ExportLearnerHelper, {
  ILearnerCentre,
  ILearnerPlacement,
} from '../../../utils/exportLearnerHelper';
import EditEmployerDetails from '../../modals/employer/EditEmployerDetails';
import EditEmployerContact from '../../modals/employer/EditEmployerContact';
import EditEmployerAddress from '../../modals/employer/EditEmployerAddress';
import AddNewSector from '../../modals/employer/AddNewSector';

const sideTabs: ISideTab[] = [
  {
    key: 'details',
    icon: 'IconPortfolio',
    title: 'Details',
    component: EditSectorDetails,
  },
  {
    key: 'learners',
    icon: 'IconUsers',
    title: 'Learners',
    component: EditSectorLearners,
  },
  {
    key: 'tasks',
    icon: 'IconTasks',
    title: 'Tasks',
    component: EditSectorTasks,
  },
];

const isBrowser = typeof window !== 'undefined';

const Employer = () => {
  if (isBrowser) {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  const [centres, setCentres] = useState<ILearnerCentre[]>([]);
  const [centresLoading, setCentresLoading] = useState<boolean>(false);
  const [centresLoaded, setCentresLoaded] = useState<boolean>(false);
  const getCentres = (): void => {
    if (!centresLoaded && !centresLoading) {
      setCentresLoading(true);
      ExportLearnerHelper.getCentres().then((response) => {
        setCentres(response);
        setCentresLoaded(true);
      });
    }
  };
  useEffect(() => {
    getCentres();
  }, [getCentres]);

  const showModal = useShowModal();

  const { employer, loadEmployer, isLoading } = useEmployer(true);

  // create array of tabs
  const tabs = useMemo(() => {
    const Component = () => <SideTabs tabs={sideTabs} />;
    return (employer?.sectors || []).map((sector, i) => ({
      title: sector.name,
      slug: String(i),
      dataQaTab: `employer-btn-${sector.name.replace(' ', '-')}`,
      component: Component,
    }));
  }, [employer?.sectors]);

  if (!employer) {
    return null;
  }

  const { name, edsNumber, levy, address, contact } = employer;
  const { email, jobTitle, name: contactName } = contact || {};
  const joinedAddress = combineAddress(address);
  const { phone } = address || {};

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {employer !== null && (
        <>
          <FullWidthContainer>
            <Header>
              <TitleWithTag>
                <h2 data-qa="employer-lbl-name">{employer.name} </h2>
                <Tag
                  isCompleted={employer?.isCompleted || false}
                  data-qa="employer-lbl-status"
                >
                  {employer.isCompleted ? 'Complete' : 'Incomplete'}
                </Tag>
              </TitleWithTag>
              <span>Last modified {formatDateTime(employer.lastModified)}</span>
            </Header>
            <GridFlex>
              <GridFlexItem small={4}>
                <DataCard
                  hasBackground
                  title="Details"
                  dataQa="employer-btn-edit-details"
                  onEdit={() => {
                    showModal(EditEmployerDetails, { loadEmployer, centres });
                  }}
                >
                  <DataCardItem
                    required
                    hideLabel
                    value={name}
                    label="Name"
                    dataQa="employer-lbl-name"
                  />
                  <DataCardItem
                    required
                    hideLabel
                    value={`${levy ? 'Levy' : 'Non-levy'} employer`}
                    label="Levy"
                    dataQa="employer-lbl-levy"
                  />
                  <DataCardItem
                    required
                    value={edsNumber}
                    label="ERN"
                    dataQa="employer-lbl-ern"
                  />
                </DataCard>
              </GridFlexItem>
              <GridFlexItem small={4}>
                <DataCard
                  hasBackground
                  title="Key Contact"
                  dataQa="employer-btn-edit-contact"
                  onEdit={() => {
                    showModal(EditEmployerContact, { loadEmployer });
                  }}
                  isEmpty={!jobTitle && !email && !contactName}
                >
                  <DataCardItem
                    required
                    hideLabel
                    value={contactName}
                    label="Name"
                    dataQa="employer-lbl-contact"
                  />
                  <DataCardItem
                    required
                    hideLabel
                    value={jobTitle}
                    label="Job title"
                    dataQa="employer-lbl-job"
                  />
                  <DataCardItem
                    required
                    hideLabel
                    value={email}
                    label="Email"
                    dataQa="employer-lbl-email"
                  />
                </DataCard>
              </GridFlexItem>
              <GridFlexItem small={4}>
                <DataCard
                  hasBackground
                  title="Address and phone number"
                  dataQa="employer-btn-edit-address"
                  onEdit={() => {
                    showModal(EditEmployerAddress, { loadEmployer });
                  }}
                  isEmpty={!address && !phone}
                >
                  <DataCardItem
                    required
                    hideLabel
                    value={joinedAddress}
                    label="Address"
                    dataQa="employer-lbl-address"
                  />
                  <DataCardItem
                    required
                    hideLabel
                    value={phone}
                    label="Phone number"
                    dataQa="employer-lbl-phone"
                  />
                </DataCard>
              </GridFlexItem>
            </GridFlex>
          </FullWidthContainer>
          <Tabbed
            tabs={tabs}
            onAdd={() => {
              showModal(AddNewSector, { loadEmployer });
            }}
            addButtonText="Add new sector"
            limit={5}
            overLimitMessage="Maximum of five sectors reached"
            dataQa="employer-btn-add-sector"
          />
        </>
      )}
    </>
  );
};

export default Employer;
