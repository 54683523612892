import React from 'react';
import {
  GridFlexContainer,
  GridFlexMain,
  IGridFlexProps,
} from './GridFlex.style';

export interface IGridFlex extends IGridFlexProps {
  children: React.ReactNode;
}

const GridFlex = ({
  children,
  gutters = true,
  direction = 'row',
  justify = 'flex-start',
  align = 'flex-start',
  overflow,
}: IGridFlex) => {
  return (
    <GridFlexContainer
      overflow={overflow}
      gutters={gutters}
      direction={direction}
      justify={justify}
      align={align}
    >
      <GridFlexMain
        overflow={overflow}
        gutters={gutters}
        direction={direction}
        justify={justify}
        align={align}
      >
        {children}
      </GridFlexMain>
    </GridFlexContainer>
  );
};

export default GridFlex;
