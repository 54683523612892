import produce from 'immer';
import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ICourse } from '../../../context-providers/course/course-types';
import {
  EmployerContext,
  useCurrentSector,
  useCurrentSectorIndex,
} from '../../../context-providers/employer/employer-context';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import { PagedResponse } from '../../../utils/paged-request';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import Select, { IOption } from '../../atoms/form-fields/select/Select';
import Stack from '../../atoms/stack/Stack';

export interface IEditSectorCourseDetails {
  loadEmployer?: () => void;
}

const EditSectorCourseDetails = ({
  loadEmployer,
}: IEditSectorCourseDetails) => {
  const closeModal = useCloseModal();
  const { state } = useContext(EmployerContext);

  const sector = useCurrentSector();
  const sectorIndex = useCurrentSectorIndex();
  const [selectedCourse, setSelectedCourse] = useState<string | undefined>();
  const [initalOption, setInitalOption] = useState<IOption | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<ICourse[]>([]);

  const loadCourses = useCallback(async () => {
    const response = await fetchAPI<PagedResponse<ICourse>>({
      path: 'courses',
      urlParams: {
        pageNumber: '1',
        recordsPerPage: '100',
        sortByAscending: 'false',
      },
    });

    if (!response.success) {
      return;
    }

    const courseByName = response.body.results.find(
      (d) => d.name === sector?.course?.name,
    );
    if (courseByName) {
      setSelectedCourse(courseByName.id);
      setInitalOption({
        value: courseByName.id,
        label: courseByName.name,
      });
    }
    setCourses(response.body.results);
    setLoading(false);
  }, [sector?.course?.name]);

  useEffect(() => {
    loadCourses();
  }, [loadCourses]);

  const handleSubmit = async (event: SyntheticEvent) => {
    const course = courses.find((d) => d.id === selectedCourse);
    const sectorCourse = { name: course?.name || '' };
    event.preventDefault();
    if (state.currentEmployer) {
      const payload = produce(state.currentEmployer, (draft) => {
        draft.sectors[sectorIndex].course = sectorCourse;
      });

      const res = await fetchAPI({
        method: 'PUT',
        body: payload,
        path: `EmployerRecords/${payload?.id || ''}`,
        responseType: 'text',
      });
      if (res.success) {
        loadEmployer && loadEmployer();
        closeModal();
      }
    }
  };

  const isValid = !!selectedCourse;

  return (
    <form onSubmit={handleSubmit}>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>Edit course details</h2>
          <p>Please choose the course for this sector.</p>
        </Stack>
        <Stack stackMultiplier={0.5}>
          {!loading && (
            <Select
              label="Course name"
              id="course"
              onOptionChanged={(option) => setSelectedCourse(option.value)}
              placeholder="Select course..."
              options={courses.map((d) => ({
                value: d.id,
                label: d.name,
              }))}
              dataQa="editCourse-dropdown-course"
              initalValue={initalOption}
              attachOutsideOfModal
            />
          )}
        </Stack>
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="editCourse-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            dataQa="editCourse-btn-save"
          >
            Save details
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default EditSectorCourseDetails;
