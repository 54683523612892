import React from 'react';
import ReactGA from '../../../utils/google-analytics';
import { getQueryVar, urlWithSetQueryVar } from '../../../utils/query-vars';
import Icon, { TIconType } from '../../icons/Icon';
import {
  Container,
  Content,
  SideMenu,
  Switcher,
  Count,
} from './SideTabs.style';

export interface ISideTab {
  key: string;
  icon: TIconType;
  title: string;
  count?: number;
  component: React.FC;
}

interface ISideTabs {
  tabs: ISideTab[];
  urlIdentifier?: string;
}

const SideTabs = ({ tabs, urlIdentifier = 'section' }: ISideTabs) => {
  const urlSection = getQueryVar(urlIdentifier);

  let sideTab: ISideTab = tabs[0];
  tabs.forEach((tab) => {
    if (tab.key === urlSection) {
      sideTab = tab;
    }
  });
  const Component = sideTab.component;

  return (
    <Container>
      <SideMenu>
        <ul role="tablist">
          {tabs.map(({ key, icon, title, count }) => (
            <li key={key} role="tab" aria-selected={sideTab.key === key}>
              <Switcher
                aria-label={sideTab.key}
                to={urlWithSetQueryVar(urlIdentifier, key)}
                data-qa={`tab-btn-${title}`}
                onClick={() => {
                  ReactGA.event({
                    category: 'Button',
                    action: key,
                  });
                }}
              >
                {icon && <Icon type={icon} responsive />}
                {title}
                {!!count && count > 0 && (
                  <Count>
                    <span>{count}</span>
                  </Count>
                )}
              </Switcher>
            </li>
          ))}
        </ul>
      </SideMenu>
      <Content>{Component && <Component />}</Content>
    </Container>
  );
};

export default SideTabs;
