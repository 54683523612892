import styled from 'styled-components';
import { color } from '../../config';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.2rem;

  & > span {
    font-size: 1.4rem;
  }
`;

export const TitleWithTag = styled.div`
  display: flex;
  align-items: center;

  & > h2 {
    margin-right: 2rem;
  }
`;

export const Tag = styled.div<{ isCompleted: boolean }>`
  display: inline-block;
  border: 1px solid
    ${(props) =>
      props.isCompleted ? color.primary.base : color.error.foreground};
  color: ${(props) =>
    props.isCompleted ? color.primary.base : color.error.foreground};
  background-color: ${(props) =>
    props.isCompleted ? color.primary.lightest : color.error.background};
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  border-radius: 0.8rem;
`;

export const ScreenActions = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;

  button {
    margin-left: 1rem;
  }
`;
