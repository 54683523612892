import { Link } from 'gatsby';
import styled from 'styled-components';
import { color } from '../../config';

export const Switcher = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  padding: 0.6rem 0.8rem;
  height: 4rem; // IE11 fix
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: 500;
  cursor: pointer;
  color: ${color.grey.dark};

  &:focus {
    outline-offset: 3px;
  }

  svg {
    margin-right: 1.6rem;
    width: 2rem;
  }
`;

export const SideMenu = styled.div`
  width: 18rem;
  border-right: 1px solid ${color.grey.light};
  padding: 3rem;

  ul {
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    margin-bottom: 1.4rem;
  }

  li[aria-selected='true'] > a {
    background-color: ${color.grey.lightest};
    color: ${color.primary.base};
    border-radius: 8px;
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 3rem;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  align-items: stretch;
`;

export const Count = styled.div`
  background-color: ${color.primary.base};
  border-radius: 50%;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;

  width: 18px;
  height: 18px;
  line-height: 18px;
  vertical-align: middle;

  text-align: center;
  font-size: 1rem;
  color: ${color.light};
`;
