import styled from 'styled-components';
import { color } from '../../config';

export const BadgeContainer = styled.div<{
  color?: string;
  border?: string;
  text?: string;
}>`
  display: inline-block;
  background-color: ${(props) => (props.color ? props.color : color.grey.dark)};
  padding: 0.5rem 1.5rem;
  font-size: 1.4rem;
  border-radius: 0.425rem;
  color: ${(props) => (props.text ? props.text : '#FFF')};
  white-space: nowrap;
  border: 1px solid ${(props) => (props.border ? props.border : '#FFF')} !important;
`;
