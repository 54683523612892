import { useCallback, useContext, useEffect, useState } from 'react';
import { fetchAPI } from '../../utils/fetch-api';
import { getQueryVar } from '../../utils/query-vars';
import { EmployerContext } from './employer-context';
import {
  IEmployer,
  IEmployerContact,
  IEmployerSector,
} from './employer-reducer';

export const useEmployer = (preload = false) => {
  const employerId = getQueryVar('eid');

  const employerContext = useContext(EmployerContext);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch, state } = employerContext;
  const employer = state.currentEmployer;

  const loadEmployer = useCallback(async () => {
    setIsLoading(true);
    const res = await fetchAPI<IEmployer>({
      path: `EmployerRecords/${employerId}`,
    });
    if (res.success) {
      dispatch({ type: 'SET_EMPLOYER', payload: res.body });
    }
    setIsLoading(false);
  }, [dispatch, employerId]);

  useEffect(() => {
    if (preload) {
      loadEmployer();
    }
  }, [preload, loadEmployer]);

  return { employer, loadEmployer, isLoading };
};

export const createEmptyEmployerContact = (): IEmployerContact => ({
  name: '',
  jobTitle: '',
  email: '',
});

export const createEmptyEmployerSector = (): IEmployerSector => ({
  name: '',
  contact: createEmptyEmployerContact(),
  course: {
    name: '',
  },
  learners: [],
});

export const createEmptyEmployer = (): IEmployer => ({
  name: '',
  edsNumber: '',
  levy: false,
  onefileOrganisationId: 0,
  onefilePlacementId: 0,
  address: {
    building: '',
    street: '',
    city: '',
    county: '',
    postcode: '',
    phone: '',
  },
  contact: createEmptyEmployerContact(),
  sectors: [createEmptyEmployerSector()],
});
