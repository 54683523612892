import React from 'react';
import ReactTooltip from 'react-tooltip';
import { getQueryVar, urlWithSetQueryVar } from '../../../utils/query-vars';
import IconButton from '../icon-button/IconButton';
import { IconPlus } from '../../icons';
import GridFlex from '../grid-flex/GridFlex';
import {
  FullWidthCropContainer,
  TabLink,
  TabLinks,
  TabSection,
} from './Tabbed.style';
import { color } from '../../config';

export interface ITab {
  slug: string;
  title: string;
  component: React.FC;
  dataQaTab?: string;
}

interface ITabbed {
  tabs: ITab[];
  onAdd?: () => void;
  addButtonText?: string;
  limit?: number;
  overLimitMessage?: string;
  dataQa?: string;
}

const Tabbed = ({
  tabs,
  onAdd,
  addButtonText = 'Add new',
  limit = 5,
  overLimitMessage = 'Maximum of five reached',
  dataQa,
}: ITabbed) => {
  const currentTab = getQueryVar('tab');
  const currentTabSpec = tabs.find((tab) => tab.slug === currentTab) || tabs[0];
  const ActiveComponent = currentTabSpec?.component;
  const hasMaximumTabs = tabs.length >= limit;

  return (
    <>
      <ReactTooltip effect="solid" backgroundColor={color.grey.base} />
      <FullWidthCropContainer>
        <GridFlex align="flex-end">
          <TabLinks>
            <div style={{ display: 'inline-block' }} role="tablist">
              {tabs.map(({ slug, title, dataQaTab }) => {
                return (
                  <TabLink
                    key={slug}
                    role="tab"
                    aria-selected={currentTabSpec.slug === slug}
                    to={urlWithSetQueryVar('tab', slug)}
                    data-qa={dataQaTab}
                  >
                    {title}
                  </TabLink>
                );
              })}
            </div>
          </TabLinks>
          <span data-tip={overLimitMessage} data-tip-disable={!hasMaximumTabs}>
            <IconButton
              icon={<IconPlus responsive />}
              onClick={() => onAdd()}
              disabled={hasMaximumTabs}
              data-qa={dataQa}
            >
              {addButtonText}
            </IconButton>
          </span>
        </GridFlex>
      </FullWidthCropContainer>
      <TabSection>{ActiveComponent && <ActiveComponent />}</TabSection>
    </>
  );
};

export default Tabbed;
