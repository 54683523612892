import React from 'react';
import {
  GridFlexItemContainer,
  IGridFlexItemContainer,
} from './GridFlexItem.style';

export interface IGridFlexItem extends IGridFlexItemContainer {
  children: React.ReactNode;
}

const GridFlexItem = ({
  children,
  gutters = true,
  xxsmall,
  xsmall,
  small,
  medium,
  large,
  xlarge,
  xxlarge,
  xxxlarge,
  'offset-xsmall': offsetXsmall,
  'offset-small': offsetSmall,
  'offset-medium': offsetMedium,
  'offset-large': offsetLarge,
  'offset-xlarge': offsetXlarge,
  'offset-xxlarge': offsetXxlarge,
  'offset-xxxlarge': offsetXxxlarge,
}: IGridFlexItem) => (
  <GridFlexItemContainer
    gutters={gutters}
    xxsmall={xxsmall}
    xsmall={xsmall}
    small={small}
    medium={medium}
    large={large}
    xlarge={xlarge}
    xxlarge={xxlarge}
    xxxlarge={xxxlarge}
    offset-xsmall={offsetXsmall}
    offset-small={offsetSmall}
    offset-medium={offsetMedium}
    offset-large={offsetLarge}
    offset-xlarge={offsetXlarge}
    offset-xxlarge={offsetXxlarge}
    offset-xxxlarge={offsetXxxlarge}
  >
    {children}
  </GridFlexItemContainer>
);

export default GridFlexItem;
