import { IEmployerAddress } from '../context-providers/employer/employer-reducer';
import { ILearnerAddress } from '../context-providers/learner/learner-reducer';

export const combineAddress = (obj?: IEmployerAddress | ILearnerAddress) => {
  return obj
    ? [obj.building, obj.street, obj.city, obj.county, obj.postcode]
        .filter(Boolean)
        .join(', ')
    : '';
};
