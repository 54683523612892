import React from 'react';
import { Link } from 'gatsby';
import { useCurrentSector } from '../../../context-providers/employer/employer-context';
import Table from '../../atoms/table/Table';
import { TableActions } from '../../atoms/table/Table.style';
import { formatDate } from '../../../utils/dates';
import { IconShow } from '../../icons';
import { IEmployerSectorLearner } from '../../../context-providers/employer/employer-reducer';
import { defaultPaged, useLocalSort } from '../../../utils/paged-request';

const EditSectorLearners = () => {
  const sector = useCurrentSector();

  const paged = defaultPaged({ sortColumnName: 'dateAdded' });

  const learners = useLocalSort<IEmployerSectorLearner>(
    paged,
    sector?.learners || [],
  );

  const renderRow = (data: IEmployerSectorLearner) => [
    data.name || '-',
    formatDate(data.dateAdded) || '-',
    <TableActions key="2">
      <Link to={`/learner/?lid=${data.id}`} data-qa="sector-btn-viewLearner">
        <IconShow responsive />
        <span className="sr-only">View {data.name} learner</span>
      </Link>
    </TableActions>,
  ];

  return (
    <Table
      caption="List of sector learners"
      data={learners}
      renderRow={renderRow}
      headerItems={[
        { sortColumnName: 'name', label: 'Learner name' },
        { sortColumnName: 'dateAdded', label: 'Date added' },
        { label: 'View' },
      ]}
      paged={paged}
    />
  );
};

export default EditSectorLearners;
