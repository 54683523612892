import produce from 'immer';
import React, { SyntheticEvent, useContext, useState } from 'react';
import { EmployerContext } from '../../../context-providers/employer/employer-context';
import { createEmptyEmployerSector } from '../../../context-providers/employer/employer-hooks';
import { IEmployerSector } from '../../../context-providers/employer/employer-reducer';
import { validateField } from '../../../context-providers/forms/forms-validation';
import { useCloseModal } from '../../../context-providers/ui/ui-hooks';
import { fetchAPI } from '../../../utils/fetch-api';
import Button from '../../atoms/button/Button';
import { ButtonRow } from '../../atoms/button/Button.style';
import TextInput from '../../atoms/form-fields/text-input/TextInput';
import Stack from '../../atoms/stack/Stack';

export interface IAddNewSector {
  loadEmployer?: () => void;
}

const AddNewSector = ({ loadEmployer }: IAddNewSector) => {
  const closeModal = useCloseModal();

  const { state } = useContext(EmployerContext);
  const [sector, setSector] = useState<IEmployerSector>(
    createEmptyEmployerSector(),
  );

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const payload = produce(state.currentEmployer, (newEmployer) => {
      newEmployer && newEmployer.sectors.push(sector);
    });

    const res = await fetchAPI({
      method: 'PUT',
      body: payload,
      path: `EmployerRecords/${payload?.id || ''}`,
      responseType: 'text',
    });
    if (res.success) {
      loadEmployer && loadEmployer();
      closeModal();
    }
  };

  const handleSectorChange = (value: string) => {
    setSector(
      produce(sector, (draft) => {
        draft.name = value;
        return draft;
      }),
    );
  };

  // field errors
  const nameErr = validateField(sector.name, { required: true });
  const isValid = !nameErr;

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <Stack stackMultiplier={2}>
        <Stack stackMultiplier={0.5}>
          <h2>Add new sector</h2>
          <p>
            What sector(s) does the employer offer apprenticeships in? These can
            be assigned to specific courses later.
          </p>
        </Stack>
        <Stack stackMultiplier={0.5}>
          <TextInput
            id="Sector_0"
            label="Sector name"
            value={sector.name}
            error={nameErr}
            dataQa="sectorModal-input-name"
            onChangeText={(str) => {
              handleSectorChange(str);
            }}
          />
        </Stack>
        <ButtonRow>
          <Button
            onClick={() => closeModal()}
            variant="subtle"
            dataQa="sectorModal-btn-cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!isValid}
            dataQa="sectorModal-btn-save"
          >
            Save details
          </Button>
        </ButtonRow>
      </Stack>
    </form>
  );
};

export default AddNewSector;
