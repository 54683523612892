import React from 'react';
import {
  IconButtonContainer,
  IconButtonIcon,
  IconButtonVariant,
} from './IconButton.style';

export interface IconButtonProps {
  variant?: IconButtonVariant;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  ariaLabel?: string;
  children?: React.ReactNode;
  icon: React.ReactNode;
  id?: string;
  dataSetType?: string;
  dataQa?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconButton = ({
  variant = 'blue',
  ariaLabel,
  dataSetType,
  id,
  children,
  icon,
  disabled = false,
  dataQa,
  ...otherProps
}: IconButtonProps) => {
  return (
    <IconButtonContainer
      aria-label={ariaLabel}
      data-id={id}
      data-set-type={dataSetType}
      disabled={disabled}
      data-qa={dataQa}
      {...otherProps}
    >
      <IconButtonIcon variant={variant} disabled={disabled}>
        {icon}
      </IconButtonIcon>
      <span>{children}</span>
    </IconButtonContainer>
  );
};

export default IconButton;
